/* eslint-disable jsx-a11y/img-redundant-alt, import/extensions */
/* global width:true */
/* eslint no-undef: "error" */

import React from "react";
import { object } from "prop-types";

import Headline from "../../atoms/headline";
import CardImage from "../../atoms/card-image";

import "./about-us.m.css";

class AboutUsMolecule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: null
    };
  }

  componentDidMount() {
    this.setState({ width });
  }

  render() {
    const { story } = this.props;
    const storyObj = story.story ? story.story : story;

    return (
      <div styleName="wrapper">
        <CardImage story={storyObj} aspectRatio={[4, 3]} imageType="image4x3" isIcon={false} />

        <div styleName="summary">
          <div>
            <Headline text="Vision" headerLevel={2} headerType={2} />
            <p styleName="statement">A world in which nature and society flourish together.</p>
          </div>

          <div>
            <Headline text="Mission" headerLevel={2} headerType={2} />
            <p styleName="statement">
              Exploring, understanding and conserving the natural world through research and responsible engagement with
              society.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUsMolecule;

AboutUsMolecule.propTypes = {
  story: object
};
