import React from "react";
import { string, func } from "prop-types";

import ExclamationIcon from "../../atoms/icons/exclamation";
import Close from "../../atoms/icons/close";

import "./error-view.m.css";

const ErrorView = ({ error, closeClick }) => {
  if (!error) {
    return null;
  }

  return (
    <div styleName="wrapper" className="error-wrapper">
      <ExclamationIcon />
      <span styleName="message">{error}</span>
      <div styleName="close-wrapper" onClick={closeClick}>
        <Close color="#FF214B" />
      </div>
    </div>
  );
};

ErrorView.propTypes = {
  error: string,
  closeClick: func
};

export default ErrorView;
