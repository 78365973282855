import React from "react";
import { object } from "prop-types";
import get from "lodash/get";

import CardImageType2 from "../../molecules/card-image-type-2";
import Headline from "../../atoms/headline";
import LinkButton from "../../atoms/link-button";
import "./single.story.m.css";

const SingleStory = ({ collection }) => {
  if (!(collection.items && collection.items.length)) {
    return null;
  }

  const storiesFromCollection = collection.items.filter(item => item.type === "story");

  const story = storiesFromCollection[0].story;

  const alternativeImage = get(story, ["alternative", "home", "default", "hero-image", "hero-image-s3-key"], "");
  return (
    <div styleName="content-wrapper">
      {story && <CardImageType2 image={story} alternativeImage={alternativeImage} />}
      <div styleName="content">
        <Headline text={story.headline} headerLevel={2} headerType={4} />
        {story.subheadline && <p styleName="description">{story.subheadline}</p>}
        <LinkButton slug={`/${story.slug}`} />
      </div>
    </div>
  );
};

SingleStory.propTypes = {
  collection: object
};

export default SingleStory;
