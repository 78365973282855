import React from "react";
import { object, string } from "prop-types";

import { ResponsiveImage, WithLazy } from "@quintype/components";

import "./card-image-type-2.m.css";

const CardImageType2 = ({ image = {}, alternativeImage = "" }) => {
  return (
    <WithLazy margin="50px">
      {() => (
        <figure styleName="qt-figure" className="qt-figure">
          <ResponsiveImage
            slug={alternativeImage || (image && image["image-s3-key"]) || image["hero-image-s3-key"]}
            aspectRatio={[16, 9]}
            defaultWidth={1598}
            widths={[250, 480, 990]}
            type="image/webp"
            sizes="( max-width: 500px ) 98%, ( max-width: 768px ) 48%, 23%"
            styleName="image"
            className="image"
            imgParams={{ auto: ["format", "compress"], fmt: "webp" }}
            alt="NCF"
          />
          <ResponsiveImage
            slug={alternativeImage || (image && image["image-s3-key"]) || image["hero-image-s3-key"]}
            aspectRatio={[16, 9]}
            defaultWidth={1598}
            widths={[250, 480, 990]}
            type="image/webp"
            sizes="( max-width: 500px ) 98%, ( max-width: 768px ) 48%, 23%"
            styleName="image"
            className="image"
            imgParams={{ auto: ["format", "compress"] }}
            alt="NCF"
          />
        </figure>
      )}
    </WithLazy>
  );
};

export default CardImageType2;

CardImageType2.propTypes = {
  image: object,
  alternativeImage: string
};
