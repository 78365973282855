import React from "react";
import { object } from "prop-types";
import flatten from "lodash/flatten";

import Carousel from "../../atoms/carousel";
import Headline from "../../atoms/headline";
import LinkButton from "../../atoms/link-button";
import CardImageType2 from "../card-image-type-2";

import "./home-carousel.m.css";

const HomeCarousel = ({ collection, options }) => {
  const storiesFromCollection = collection.items.filter(item => item.type === "story");
  const children = storiesFromCollection
    .map(({ story }, key) => {
      // Pick only the story images from the card
      const cardImagesList =
        story.cards &&
        story.cards.length &&
        story.cards.map(card => card["story-elements"].filter(storyElement => storyElement.type === "image"));

      // If card doesn't have any images then neglect it
      if (!(cardImagesList && cardImagesList.length)) {
        return null;
      }

      const imageList = flatten(cardImagesList);

      // If card doesn't have any images then neglect it
      if (!(imageList && imageList.length)) {
        return null;
      }

      // Fetch random image from the image list
      const image = imageList[Math.floor(Math.random() * imageList.length)];
      const getSectionSlug = (story.sections && story.sections[0].slug) || "";

      return (
        <div styleName="content-wrapper" key={key}>
          {story && <CardImageType2 image={image} />}
          <div styleName="content">
            <Headline text={story.headline} headerLevel={2} headerType={4} />
            {story.subheadline && <p styleName="description">{story.subheadline}</p>}
            <LinkButton slug={`/${getSectionSlug}`} />
          </div>
        </div>
      );
    })
    .filter(story => story); // Remove story objects which are null

  return (
    <div>
      <Carousel
        carouselName={`carousel-${collection.slug}`}
        options={options}
        showDots={true}
        totalItems={storiesFromCollection.length}
      >
        {children}
      </Carousel>
    </div>
  );
};

export default HomeCarousel;

HomeCarousel.propTypes = {
  collection: object.isRequired,
  options: object.isRequired
};
