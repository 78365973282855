import React, { useState, useEffect } from "react";
import { string, func } from "prop-types";
import { connect } from "react-redux";
import { parseUrl } from "query-string";

import ErrorView from "../../molecules/error-view";

import { verifyEmailOtp } from "../../helper/api";
import { setUserLoggedIn, setLoading } from "../../helper/actions";

import "./verify-email.m.css";

const VerifyEmailView = ({ currentPath, setUserLoggedIn, navigateToPage, setLoading }) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    const params = parseUrl(currentPath);
    if (params && params.query) {
      setLoading(true);

      // Do a 2 seconds delay to show loading view
      setTimeout(() => {
        verifyEmailOtp(params.query.otp || 0, params.query["member-id"] || 0)
          .then(response => {
            setUserLoggedIn(true, response.member);
            navigateToPage("/publications");
          }) // On success
          .catch(ex => setError("Invalid User/OTP"))
          .finally(() => setLoading(false));
      }, 2000);
    } else {
      setError("Invalid User/OTP");
    }
  }, []);

  return (
    <div styleName="wrapper">
      {error ? null : <span styleName="label">Verifying your Account</span>}
      <ErrorView error={error} />
    </div>
  );
};

VerifyEmailView.propTypes = {
  currentPath: string,
  setUserLoggedIn: func,
  navigateToPage: func,
  setLoading: func
};

const mapDispatchToProps = dispatch => ({
  setUserLoggedIn: (isUserLoggedIn, user) => dispatch(setUserLoggedIn(isUserLoggedIn, user)),
  navigateToPage: url => global.app.navigateToPage(dispatch, url),
  setLoading: isLoading => dispatch(setLoading(isLoading))
});

const VerifyEmailPage = connect(
  null,
  mapDispatchToProps
)(VerifyEmailView);

export { VerifyEmailPage };
