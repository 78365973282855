import React from "react";
import { object, array } from "prop-types";
import { connect } from "react-redux";
import { Link } from "@quintype/components";
import get from "lodash/get";

import Headline from "../../atoms/headline";
import ByName from "../../atoms/by-name";
import CardImage from "../../atoms/card-image";
import Placeholder from "../../atoms/icons/placeholder";
import PublicationEntityImage from "../../atoms/publication-entity-image";
import { LabelWithBackground } from "../../atoms/label";

import "./card-image-type-7.m.css";

const CardImageType7 = ({ story, entityList }) => {
  const storyObj = story.story ? story.story : story;
  const authorObj = get(storyObj, ["authors", "0"], null);
  const authorObjId = (authorObj && authorObj.id) || "";
  const authorObjSlug = (authorObj && authorObj.slug) || "";
  const authorName = (authorObj && authorObj.name) || "";
  let containerView = null;
  const referenceUrl = get(storyObj, ["metadata", "reference-url"], null);

  const getPublicationImageView = () => {
    if (storyObj["story-template"] === "publication" && entityList) {
      const publicationTypeObj = get(storyObj, ["metadata", "story-attributes", "publicationtype", "0"], null);

      if (publicationTypeObj) {
        const publicationTypeEntity = entityList.find(c => c.id === publicationTypeObj.id);

        if (publicationTypeEntity) {
          return (
            <PublicationEntityImage
              entity={publicationTypeEntity}
              defaultWidth={36}
              widths={[300]}
              aspectRatio={[1, 2]}
              imageType="image1x2"
            />
          );
        }
      }
    }

    return <Placeholder />;
  };

  const imageView = (
    <React.Fragment>
      {storyObj["hero-image-s3-key"] ? (
        <CardImage story={storyObj} defaultWidth={36} aspectRatio={[16, 9]} imageType="image16x9" widths={[364, 728]} />
      ) : (
        getPublicationImageView()
      )}
      {storyObj["story-template"] === "news-elsewhere" ? <LabelWithBackground text="NCF in the NEWS" /> : null}
    </React.Fragment>
  );

  // Check for external story
  if (referenceUrl) {
    containerView = (
      <a styleName="card-img" href={referenceUrl} target="_blank" rel="noopener noreferrer">
        {imageView}
      </a>
    );
  } else {
    containerView = (
      <Link href={`/${storyObj.slug}`} styleName="card-img">
        {imageView}
      </Link>
    );
  }

  const contentView = (
    <React.Fragment>
      <Headline text={storyObj.headline} headerType={8} headerLevel={3} />
      {storyObj.subheadline && (
        <div styleName="subheadline">
          <Headline text={storyObj.subheadline} headerType={11} headerLevel={4} />
        </div>
      )}
    </React.Fragment>
  );

  return (
    <div styleName="wrapper" className="card-image-type7">
      {containerView}
      <div styleName="card-content">
        {referenceUrl ? (
          <a href={referenceUrl} target="_blank" rel="noopener noreferrer">
            {contentView}
          </a>
        ) : (
          <Link href={`/${storyObj.slug}`}>{contentView}</Link>
        )}
        <div styleName="by-name">
          <ByName
            name={authorName}
            id={authorObjId.toString()}
            authorSlug={authorObjSlug}
            date={storyObj["first-published-at"]}
          />
        </div>
      </div>
    </div>
  );
};

CardImageType7.propTypes = {
  story: object,
  entityList: array
};

const mapStateToProps = state => {
  return {
    entityList: get(state, ["entityReducer"], [])
  };
};

export default connect(
  mapStateToProps,
  null
)(CardImageType7);
