import React from "react";
import { object, array } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import sortBy from "lodash/sortBy";

import Headline from "../../atoms/headline";
import AuthorDetails from "../../molecules/author-details";

import { filterAlumnusAuthors } from "../../helper/utils";

import "./team.m.css";

const TeamRow = ({ collection = {}, authorList }) => {
  const items = collection.items || [];
  const projectAuthorList = [];

  const projectStoryList = items.filter(
    item =>
      item.type === "story" &&
      item.story["story-template"] === "project" &&
      item.story.authors &&
      item.story.authors.length
  );

  projectStoryList.forEach(({ story }) => {
    story.authors.forEach(author => !projectAuthorList.some(a => a.id === author.id) && projectAuthorList.push(author));
  });

  // Remove the authors whose metadata.status is 'Alumnus' and
  // Shuffle the authors so that on every page load you see different authors
  const shuffledAuthor = filterAlumnusAuthors(authorList, projectAuthorList).sort(() => 0.5 - Math.random());

  // Display only 6 authors
  // Remove this author from being displayed -> author/647041/admin
  const sortedAuthors = shuffledAuthor.filter(c => c.id !== 647041).slice(0, 6);
  const sortedList = sortBy(sortedAuthors, [author => author.name]);
  const children =
    sortedList && sortedList.map((author, index) => <AuthorDetails key={index} author={author} isDesignation={true} />);

  return (
    <div className="container">
      <div styleName="header-wrapper">
        <Headline headerLevel={2} headerType={2} text="TEAM" />
      </div>
      <div styleName="wrapper">{children}</div>
      <div styleName="load-more">
        <a className="default-link-button" href="/people">
          SHOW MORE
        </a>
      </div>
    </div>
  );
};

TeamRow.propTypes = {
  collection: object,
  authorList: array
};

const mapStateToProps = state => {
  return {
    authorList: get(state, ["peopleReducer"], [])
  };
};

export default connect(
  mapStateToProps,
  null
)(TeamRow);
