import React from "react";
import { object, number, func } from "prop-types";
import { connect } from "react-redux";

import { setLoading } from "../../helper/actions";
import CardImageType7 from "../../molecules/card-image-type-7";
import ShowMore from "../../atoms/show-more";
import { storyFields } from "../../../../isomorphic/constants";

import "../../pages/listing.m.css";
class LoadMoreSearchResults extends React.Component {
  constructor(props) {
    super(props);
    const { total, stories, tag } = props.data;

    this.state = {
      stories: stories,
      totalNumberOfStories: total,
      offset: stories.length,
      query: tag.name,
      itemsToLoad: props.itemsToLoad || 10,
      noMoreStories: stories.length >= total
    };
  }

  loadMore = () => {
    const { itemsToLoad, offset, stories, query } = this.state;
    const { setLoading } = this.props;

    setLoading(true);

    return global
      .wretch("/api/v1/advanced-search")
      .query({
        q: query,
        offset: offset,
        limit: itemsToLoad,
        fields: storyFields
      })
      .get()
      .json(response => {
        const updatedResults = [].concat(stories, response.items);
        this.setState({
          stories: updatedResults,
          offset: offset + itemsToLoad,
          noMoreStories: updatedResults.length >= this.state.totalNumberOfStories
        });
      })
      .finally(() => setLoading(false));
  };

  render() {
    const { stories, noMoreStories } = this.state;

    return (
      <React.Fragment>
        {stories.length && stories.map((item, index) => <CardImageType7 story={item} key={index} />)}
        {!noMoreStories && (
          <div styleName="show-more">
            {" "}
            <ShowMore text="LOAD MORE" onClick={this.loadMore} />
          </div>
        )}
      </React.Fragment>
    );
  }
}

LoadMoreSearchResults.propTypes = {
  data: object,
  itemsToLoad: number,
  setLoading: func
};

const mapDispatchToProps = dispatch => ({
  setLoading: isLoading => dispatch(setLoading(isLoading))
});

export default connect(
  null,
  mapDispatchToProps
)(LoadMoreSearchResults);
