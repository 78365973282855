import React from "react";
import { array, string, func } from "prop-types";

import "./dropdown.m.css";

const Dropdown = ({ label, list, value, onChange, defaultOption }) => {
  if (!(list && list.length)) {
    return null;
  }

  return (
    <div styleName="wrapper">
      {label && <label styleName="label">{label}</label>}
      <select styleName="select-wrapper" name="entity" value={value} onChange={onChange} className="select-wrapper">
        {defaultOption && <option value="">{defaultOption}</option>}
        {list.map((item, index) => {
          return item !== null && item.id ? (
            <option key={index} value={item.id} name="entity">
              {item.name}
            </option>
          ) : (
            item !== null && (
              <option key={index} value={item} name="entity">
                {item}
              </option>
            )
          );
        })}
      </select>
    </div>
  );
};

Dropdown.defaultProps = {
  defaultOption: ""
};

Dropdown.propTypes = {
  value: string.isRequired,
  onChange: func.isRequired,
  label: string,
  list: array,
  defaultOption: string
};

export default Dropdown;
