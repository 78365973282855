import React from "react";
import { object, func, array, bool } from "prop-types";
import { LoadMoreCollectionStories } from "@quintype/components";
import get from "lodash/get";

import Headline from "../../atoms/headline";
import TopStories from "../../rows/top-stories";
import CardImageType7 from "../../molecules/card-image-type-7";
import CardImage from "../../atoms/card-image";
import ShowMore from "../../atoms/show-more";

import "./blog.m.css";

const BlogPage = ({ data }) => {
  const coverImage = get(data.collection, ["metadata", "cover-image", "cover-image-s3-key"], "");
  const storyObj = {
    "hero-image-s3-key": coverImage,
    "hero-image-metadata": "",
    "hero-image-caption": "cover image",
    alt: "cover image"
  };

  let imageView = null;

  if (coverImage) {
    imageView = (
      <CardImage story={storyObj} defaultWidth={240} aspectRatio={[4, 3]} imageType="image8x55" widths={[240, 120]} />
    );
  }
  const textStories =
    (data.collection && data.collection.items.filter(item => item.story["story-template"] === "text")) || [];

  const restOfCollectionItem = {
    items: textStories.slice(5, textStories.length)
  };

  const collectionItem = stories => {
    const collectionItems = {
      items: stories.map(item => {
        return (item = {
          story: item.story,
          type: "story"
        });
      })
    };

    return collectionItems;
  };

  const MoreCollectionStories = ({ stories, onLoadMore, noMoreStories }) => {
    return (
      <div styleName="other-stories" className="container">
        <div styleName="content-left">
          {stories && stories.slice(10).map((item, index) => <CardImageType7 story={item} key={index} />)}
          {stories.length < data.totalCount && (
            <div styleName="show-more">
              {" "}
              <ShowMore text="LOAD MORE" onClick={onLoadMore} />
            </div>
          )}
        </div>
      </div>
    );
  };

  return textStories.length ? (
    <div styleName="wrapper">
      <div styleName="title">
        <Headline text={(data.collection && data.collection.summary) || "NCF Blog"} headerType={1} headerLevel={2} />
      </div>
      {imageView && imageView}
      <div styleName="top-stories">
        <TopStories collection={collectionItem(textStories)} showTitle={false} showReadmore={false} />
      </div>
      <div styleName="other-stories" className="container">
        <div styleName="content-left">
          {restOfCollectionItem &&
            restOfCollectionItem.items.map((item, index) => <CardImageType7 story={item} key={index} />)}
        </div>
      </div>

      <LoadMoreCollectionStories
        template={MoreCollectionStories}
        data={{ stories: textStories }}
        collectionSlug={data.collection.slug}
        numStoriesToLoad={10}
        params={{ "item-type": "story" }}
      />
    </div>
  ) : (
    <div styleName="wrapper">
      <div styleName="title">
        <Headline text="No stories found!" headerType={1} headerLevel={2} />;
      </div>
    </div>
  );
};

export { BlogPage };
BlogPage.propTypes = {
  data: object,
  onLoadMore: func,
  stories: array,
  noMoreStories: bool
};
