import React from "react";
import { object, number, array } from "prop-types";
import { Link } from "@quintype/components";

import Headline from "../../atoms/headline";
import CardImage from "../../atoms/card-image";

import "./card-image-type-1.m.css";
import { Label, LabelWithBackground } from "../../atoms/label";

const populateTags = (story = {}) => {
  const { tags = [] } = story;
  return tags.filter(item => item["name"] === "Completed").map(tag => <Label key={tag.id} text={tag.name} />);
};
const CardImageType1 = ({ story, headlineLimit, widths = [250, 375] }) => {
  const storyObj = story.story ? story.story : story;
  const storySectionName = storyObj.sections[0].name;

  return (
    <Link href={`/${storyObj.slug}`}>
      <div styleName="wrapper" className="card-image-type-1">
        <CardImage
          story={storyObj}
          defaultWidth={259}
          aspectRatio={[267, 332]}
          imageType="image267x332"
          widths={widths}
        />
        <div styleName="sections">
          <LabelWithBackground text={storySectionName} />
        </div>
        <div styleName="tags">{populateTags(storyObj)}</div>
        <Headline text={storyObj.headline} headerType={2} headerLevel={3} headlineLimit={headlineLimit} />
      </div>
    </Link>
  );
};

export default CardImageType1;

CardImageType1.propTypes = {
  story: object,
  headlineLimit: number,
  widths: array
};
