import React from "react";

import Login from "../../rows/login";

import "./login.m.css";

const LoginPage = () => {
  return (
    <div>
      <div styleName="top-content">
        <span styleName="login-label">LOGIN</span>
        <span styleName="new-user">
          New User?{" "}
          <a href="/signup" styleName="click-here">
            Click here
          </a>{" "}
          to register.
        </span>
      </div>
      <div styleName="bottom-content">
        <Login />
      </div>
    </div>
  );
};

export { LoginPage };
