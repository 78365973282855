import React, { useState, useEffect } from "react";
import { func, array } from "prop-types";
import { connect } from "react-redux";
import sortBy from "lodash/sortBy";
import get from "lodash/get";

import { setLoading } from "../../helper/actions";

import Headline from "../../atoms/headline";
import AuthorDetails from "../../molecules/author-details";
import Dropdown from "../../atoms/dropdown";

import "./people.m.css";

const PROGRAMME = "Programme";
const DESIGNATION = "Designation";

const PeoplePageView = ({ headerMenuLinks, setLoading, authorList }) => {
  const [type, setType] = useState(PROGRAMME);
  const [programs, setPrograms] = useState([]);
  const [designations, setDesignations] = useState([]);

  useEffect(
    () => {
      if (authorList.length) {
        processAuthors(authorList);
      }
    },
    [authorList.length]
  );

  const processAuthors = authors => {
    const designationList = [];
    const programList = [];

    let list = authors.reduce((acc, author) => {
      if (author.metadata && author.metadata["Designation"] && author.metadata.status !== "Alumnus") {
        if (!acc[author.metadata["Designation"]]) {
          acc[author.metadata["Designation"]] = [];
        }

        acc[author.metadata["Designation"]].push(author);
      }

      return acc;
    }, {});

    // Convert object into list
    if (list) {
      for (const key in list) {
        designationList.push({
          title: key,
          authorList: list[key]
        });
      }
    }

    // Decide the author's metadata column name
    const tempList = authors.filter(a => a.metadata && a.metadata["Home Programme"]);
    const homeProgramName = tempList.length ? "Home Programme" : "homeProgramme";

    list = authors.reduce((acc, author) => {
      if (author.metadata && author.metadata[homeProgramName] && author.metadata.status !== "Alumnus") {
        if (!acc[author.metadata[homeProgramName]]) {
          acc[author.metadata[homeProgramName]] = [];
        }

        acc[author.metadata[homeProgramName]].push(author);
      }

      return acc;
    }, {});

    // Convert object into list
    if (list) {
      const tempProgramList = [];

      for (const key in list) {
        tempProgramList.push({
          title: key,
          authorList: list[key],
          flag: false // used for sorting against section programmes
        });
      }

      // Get the programmes section names
      const programHeaderObj = headerMenuLinks.find(c => c.title === "Programmes");
      const programHeaderList = (programHeaderObj && programHeaderObj.children) || [];
      let position = -1;

      // Below foreach sorts the title as per the header programmes section names
      programHeaderList.forEach(item => {
        position = tempProgramList.findIndex(c => c.title.toLowerCase() === item.title.toLowerCase());

        if (position !== -1) {
          programList.push(tempProgramList[position]);
          tempProgramList[position].flag = true; // Set the flag to true to indicate its processed
        }
      });

      tempProgramList.filter(c => !c.flag).map(c => programList.push(c)); // add all the flag which is false to end of the program list89
      programList.map(c => delete c.flag); // delete the temporary flag created earlier
    }

    setPrograms(programList);
    setDesignations(designationList);
    setLoading(false);
  };

  const getAuthorDom = (item, index, isDesignation) => {
    return (
      <div key={index} styleName="author-wrapper">
        <div styleName="sub-title">
          <Headline
            text={item.title === "Institutional staff" ? "Institutional Staff" : item.title}
            headerType={9}
            headerLevel={3}
          />
        </div>
        <div styleName="people-row">
          {sortBy(item.authorList, [author => author.name]).map(item => (
            <div styleName="people" key={`${item.id}-${index}`}>
              <AuthorDetails author={item} isDesignation={isDesignation} />
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div styleName="wrapper">
        <div styleName="title">
          <Headline text="PEOPLE" headerType={1} headerLevel={1} />
        </div>
      </div>

      <div styleName="middle-container">
        <div className="container">
          <Dropdown
            label="Sort By"
            list={[PROGRAMME, DESIGNATION]}
            value={type}
            onChange={e => setType(e.target.value)}
          />
        </div>
      </div>
      <div styleName="wrapper">
        {type === PROGRAMME
          ? programs.map((item, index) => getAuthorDom(item, index, true))
          : designations.map((item, index) => getAuthorDom(item, index, false))}
      </div>
    </React.Fragment>
  );
};

PeoplePageView.propTypes = {
  headerMenuLinks: array,
  setLoading: func,
  authorList: array
};

const mapStateToProps = state => {
  return {
    authorList: get(state, ["peopleReducer"], []),
    headerMenuLinks: get(state, ["qt", "data", "navigationMenu", "headerMenuLinks"], [])
  };
};

const mapDispatchToProps = dispatch => ({
  setLoading: isLoading => dispatch(setLoading(isLoading))
});

const PeoplePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(PeoplePageView);

export { PeoplePage };
