import React from "react";
import { object, bool } from "prop-types";
import { Link } from "@quintype/components";
import get from "lodash/get";

import Headline from "../../atoms/headline";
import CardImage from "../../atoms/card-image";
import Placeholder from "../../atoms/icons/placeholder";
import { LabelWithBackground } from "../../atoms/label";

import "./card-image-type-4.m.css";

const CardImageType4 = ({ story, isNcfNews }) => {
  const storyObj = story.story ? story.story : story;
  let imageView = null;
  let containerView = null;
  const referenceUrl = get(storyObj, ["metadata", "reference-url"], null);

  if (storyObj["story-template"] === "news-elsewhere") {
    imageView = (
      <div className="card-image-type-3" styleName="image-view">
        {storyObj["hero-image-s3-key"] ? (
          <CardImage story={storyObj} defaultWidth={36} aspectRatio={[16, 9]} imageType="image16x9" />
        ) : (
          <Placeholder />
        )}
        {isNcfNews ? <LabelWithBackground text="NCF in the NEWS" /> : null}
      </div>
    );
  } else {
    imageView = (
      <div styleName="wrapper" className="card-image-type-3">
        {storyObj["hero-image-s3-key"] ? (
          <CardImage story={storyObj} defaultWidth={36} aspectRatio={[16, 9]} imageType="image16x9" />
        ) : (
          <Placeholder />
        )}
      </div>
    );
  }

  const contentView = (
    <React.Fragment>
      {imageView}
      <Headline text={storyObj.headline} headerType={9} headerLevel={3} />
      {storyObj.subheadline && <p styleName="summary">{storyObj.subheadline}</p>}
    </React.Fragment>
  );

  // Check for external story
  if (referenceUrl) {
    containerView = (
      <a href={referenceUrl} target="_blank" rel="noopener noreferrer">
        {contentView}
      </a>
    );
  } else {
    containerView =
      storyObj["story-template"] === "visual-story" ? (
        <a href={`/${storyObj.slug}`} target="_blank" rel="noopener noreferrer">
          {contentView}
        </a>
      ) : (
        <Link href={`/${storyObj.slug}`}>{contentView}</Link>
      );
  }

  return <div styleName="container">{containerView}</div>;
};

CardImageType4.defaultProps = {
  isNcfNews: true
};

CardImageType4.propTypes = {
  story: object,
  isNcfNews: bool
};

export default CardImageType4;
