import React from "react";
import PropTypes from "prop-types";
import { Link } from "@quintype/components";

import CarouselSlider from "../../molecules/carousel-slider";
import Headline from "../../atoms/headline";
import { LabelWithBackground } from "../../atoms/label";

import "./featured-projects.m.css";

const FeaturedProjectsRow = ({ collection, showTitle = true, entities = [] }) => {
  const themeEntities = entities.filter(item => item.type === "Theme") || [];
  const projectStories =
    (collection && collection.items && collection.items.filter(item => item.story["story-template"] === "project")) ||
    [];
  const collectionItem = stories => {
    const collectionItems = {
      items: stories.map(item => {
        return (item = {
          story: item,
          type: "story"
        });
      })
    };
    return collectionItems;
  };

  return (
    <div styleName="wrapper" className="container featured-project">
      {showTitle && (
        <div styleName="header-wrapper">
          <Headline headerLevel={2} headerType={2} text="Featured Projects" />
        </div>
      )}

      <CarouselSlider
        collection={collectionItem(projectStories)}
        options={{
          type: "slider",
          perView: 4,
          gap: 24,
          breakpoints: { 480: { perView: 1, peek: { before: 0, after: 80 } } }
        }}
      />

      {entities.length > 0 && (
        <div styleName="entities">
          <Headline headerLevel={3} headerType={2} text="Themes" />
          {themeEntities.map((item, index) => (
            <Link href={`/topic/${item.slug}`} styleName="entity" key={index}>
              <LabelWithBackground text={item.name} />
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};
FeaturedProjectsRow.propTypes = {
  collection: PropTypes.object,
  showTitle: PropTypes.bool,
  entities: PropTypes.array
};

export default FeaturedProjectsRow;
