import React from "react";
import { object } from "prop-types";

import Headline from "../../atoms/headline";
import StoryCard from "../../story-templates/story-card";
import PeopleTeamRow from "../../rows/people-team";
import CardImageType8 from "../../molecules/card-image-type-8";

import "./phd.m.css";

const PhdPage = ({ data }) => {
  const textStory =
    data.collection &&
    data.collection.items &&
    data.collection.items.filter(item => item.story["story-template"] === "text");
  const story = textStory && textStory.length && textStory[0].story;

  const getPhdStudentsBasedOnRole = role => {
    return textStory.length && story.authors.filter(item => item["contributor-role"].name === role);
  };

  const currentPhdStudents = getPhdStudentsBasedOnRole("Current PHD Students");
  const pastPhdStudents = getPhdStudentsBasedOnRole("Past PHD students");
  const researchScholars = getPhdStudentsBasedOnRole("Research Scholars");

  const projectStories =
    data.collection &&
    data.collection.items &&
    data.collection.items.filter(item => item.story["story-template"] === "project");

  return (
    <div styleName="wrapper">
      {textStory.length > 0 && (
        <div styleName="title">
          <Headline text={story.headline} headerType={1} headerLevel={2} />
        </div>
      )}

      <div styleName="story-wrapper">
        {textStory.length > 0 && (
          <div styleName="story-content">
            {story.cards.map(card => (
              <StoryCard key={card.id} card={card} story={story} />
            ))}
          </div>
        )}

        {textStory.length > 0 &&
          currentPhdStudents && (
            <div styleName="people-row">
              <PeopleTeamRow authors={currentPhdStudents} headerText="CURRENT PH.D. STUDENTS " />
            </div>
          )}
        {textStory.length > 0 &&
          pastPhdStudents && (
            <div styleName="people-row">
              <PeopleTeamRow authors={pastPhdStudents} headerText="PAST PH.D. STUDENTS" />
            </div>
          )}
        {textStory.length > 0 &&
          researchScholars && (
            <div styleName="people-row">
              <PeopleTeamRow authors={researchScholars} headerText="RESEARCH SCHOLARS" />
            </div>
          )}

        {projectStories && (
          <React.Fragment>
            <Headline text=" PROJECTS" headerType={9} headerLevel={2} />
            <div styleName="project-stories">
              {projectStories.map((item, index) => (
                <CardImageType8 story={item} key={index} />
              ))}
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

PhdPage.propTypes = {
  data: object
};

export { PhdPage };
