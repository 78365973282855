import React, { useState, useEffect } from "react";
import { HomePage } from "./home";

const HomePagePreview = () => {
  const [stories, setStories] = useState();

  useEffect(() => {
    global.addEventListener("message", event => {
      if (event.data.story) {
        setStories(Array(10).fill(event.data.story));
      }
    });
  }, []);

  if (!stories) return <div />;
  return <HomePage data={{ stories }} />;
};

export { HomePagePreview };
