import { wrapCollectionLayout, EagerLoadImages } from "@quintype/components";
import React from "react";
import HomeCarouselRow from "../rows/home-carousel";
import AboutUs from "../rows/about-us";
import SingleStory from "../rows/single-story";
import FeaturedProjectsRow from "../rows/featured-projects";
import TopStoriesRow from "../rows/top-stories";
import TeamRow from "../rows/team";
import BlogView from "../rows/blog-view";
import StorySlider from "../rows/story-slider";

function wrapEager(f) {
  return function WrapEager(props) {
    if (props.index === 0) {
      return (
        <EagerLoadImages predicate={token => token === "above-fold"}>{React.createElement(f, props)}</EagerLoadImages>
      );
    } else {
      return React.createElement(f, props);
    }
  };
}

export default {
  HomeCarousel: wrapEager(wrapCollectionLayout(HomeCarouselRow)),
  TopStories: wrapEager(wrapCollectionLayout(TopStoriesRow)),
  FeaturedProjects: wrapEager(wrapCollectionLayout(FeaturedProjectsRow)),
  AboutUs: wrapEager(wrapCollectionLayout(AboutUs)),
  SingleStory: wrapEager(wrapCollectionLayout(SingleStory)),
  Team: wrapEager(wrapCollectionLayout(TeamRow)),
  BlogView: wrapEager(wrapCollectionLayout(BlogView)),
  StorySlider: wrapEager(wrapCollectionLayout(StorySlider)),
  defaultTemplate: wrapEager(wrapCollectionLayout(HomeCarouselRow))
};
