import React from "react";
import { object, number, func, bool } from "prop-types";
import { connect } from "react-redux";
import { LoadMoreStoriesBase } from "@quintype/components";

import { setLoading } from "../../helper/actions";
import { sortStories } from "../../helper/utils";
import { getPublicationEntitiesYear, getStoriesByAttribute, searchStories } from "../../../components/helper/api";

import CardImageType6 from "../../molecules/card-image-type-6";
import ShowMore from "../../atoms/show-more";
import Headline from "../../atoms/headline";
import Dropdown from "../../atoms/dropdown";

import "./publications.m.css";

class LoadMoreSearchResults extends React.Component {
  state = {
    stories: [],
    totalNumberOfStories: "",
    offset: "",
    query: "",
    itemsToLoad: "",
    tagName: "",
    section: "",
    year: "",
    publicationList: [],
    programList: [],
    yearList: [],
    total: "",
    params: {},
    queryString: "",
    currentDropdownList: "",
    storyCount: ""
  };

  fields =
    "headline,subheadline,summary,sections,tags,author-name,author-id,authors,updated-at,last-published-at,published-at,updated-at,first-published-at,metadata,hero-image-metadata,hero-image-s3-key,story-content-id,slug,id,seo,story-template,cards,metadata,access"; // eslint-disable-line

  componentDidMount() {
    const { data, setLoading } = this.props;
    const publicationTypeEntities = data.entities.filter(item => item.type === "publication-type");
    const publicationList = (publicationTypeEntities && publicationTypeEntities.map(item => item.name)) || [];

    const menuList = data.navigationMenu.headerMenuLinks;
    const programMenu = menuList.filter(item => item.title === "Programmes");
    const programList =
      programMenu &&
      programMenu.length &&
      programMenu[0].children.map(item => {
        return (
          item["section-name"] && {
            name: item["section-name"],
            id: item["item-id"]
          }
        );
      });

    const programSubMenu = programMenu.length && programMenu[0].children.filter(item => item.title === "Archives");
    const programSubMenuList =
      programSubMenu.length &&
      programSubMenu[0].children.map(item => {
        return {
          name: item["section-name"],
          id: item["item-id"]
        };
      });

    setLoading(true);

    // Get the entities whose type is 'publication-year'
    getPublicationEntitiesYear()
      .then(results => {
        // Convert year to integer and push In press in 1st position
        let yearList = (results && results.map(item => parseInt(item.name))) || [];
        yearList = yearList.filter(c => !isNaN(c)).sort((a, b) => b - a);
        yearList = ["In press", ...yearList];

        this.setState({
          publicationList,
          yearList: yearList,
          programList: programList.concat(programSubMenuList)
        });

        setLoading(false);
        this.getStories();
      })
      .catch(() => setLoading(false));
  }

  onChangeFunc = type => e => {
    const value = encodeURIComponent(e.target.value);
    if (type === "publication" && e.target.value) {
      this.setState(
        {
          tagName: e.target.value,
          stories: [],
          year: "Year",
          section: "All program",
          queryString: `publicationtype.name=${value}`,
          currentDropdownList: "publication"
        },
        () => {
          this.getStoriesByAttribute();
        }
      );
    } else if (type === "year" && e.target.value) {
      this.setState(
        {
          year: e.target.value,
          stories: [],
          section: "All program",
          tagName: "All publications",
          queryString: `publicationyear.name=${value}`,
          currentDropdownList: "year"
        },
        () => {
          this.getStoriesByAttribute();
        }
      );
    } else if (type === "program" && e.target.value) {
      this.setState(
        {
          section: e.target.value,
          stories: [],
          year: "Year",
          tagName: "All publications",
          currentDropdownList: "program"
        },
        () => {
          this.getStories();
        }
      );
    } else {
      this.setState({ section: "", tagName: "", stories: [], year: "" }, () => {
        this.getStories();
      });
    }
  };

  getParams = () => {
    const { section, stories } = this.state;

    if (section) {
      return {
        type: "publication",
        "section-id": section,
        offset: stories.length,
        fields: this.fields
      };
    } else {
      return {
        type: "publication",
        offset: stories.length,
        fields: this.fields
      };
    }
  };

  getStoriesByAttribute = () => {
    const { setLoading, isLoading } = this.props;
    const { stories, queryString } = this.state;

    // check if something is in progress
    if (isLoading) {
      return;
    }

    setLoading(true);

    getStoriesByAttribute(queryString, stories.length, this.fields)
      .then(responseStoryList => {
        const updatedResults = [].concat(stories, responseStoryList);

        this.setState({
          stories: sortStories(updatedResults),
          offset: stories.length + 1,
          storyCount: responseStoryList.length
        });
      })
      .finally(() => setLoading(false));
  };

  getStories = () => {
    const { setLoading, isLoading } = this.props;
    const { stories } = this.state;

    // check if something is in progress
    if (isLoading) {
      return;
    }

    setLoading(true);
    const params = { ...this.getParams(), sort: "published-at", limit: 20 };

    searchStories(params)
      .then(responseStoryList => {
        const updatedResults = [].concat(stories, responseStoryList);

        this.setState({
          stories: sortStories(updatedResults),
          offset: stories.length + 1,
          storyCount: responseStoryList.length
        });
      })
      .finally(() => setLoading(false));
  };

  getProperStories = () => {
    const { currentDropdownList, queryString } = this.state;

    if (currentDropdownList === "program" || currentDropdownList === "" || !queryString) {
      this.getStories();
    } else if (currentDropdownList === "year" || currentDropdownList === "publication") {
      this.getStoriesByAttribute();
    }
  };

  storiesDom = stories =>
    stories.map((item, index) => {
      const storyObj = item.story || item;

      return (
        <div key={index}>
          <CardImageType6 story={storyObj} />
        </div>
      );
    });

  StoriesTemplate = ({ stories }) => {
    return (
      <React.Fragment>
        <div styleName="wrapper">{this.storiesDom(stories)}</div>

        {this.state.storyCount >= 20 && (
          <div styleName="show-more">
            {this.props.isLoading ? null : <ShowMore text="SHOW MORE" onClick={this.getProperStories} />}
          </div>
        )}
      </React.Fragment>
    );
  };

  render() {
    const { programList, section, stories, yearList, publicationList, tagName, year } = this.state;
    const { isLoading } = this.props;

    return (
      <React.Fragment>
        <div styleName="middle-content">
          <div styleName="wrapper">
            {publicationList.length >= 1 && (
              <Dropdown
                label="Publication"
                list={publicationList}
                value={tagName}
                onChange={this.onChangeFunc("publication")}
                defaultOption="All publications"
              />
            )}
            {programList.length >= 1 && (
              <Dropdown
                label="Programmes"
                list={programList}
                value={section}
                onChange={this.onChangeFunc("program")}
                defaultOption="All Programs"
              />
            )}
            <Dropdown
              label="Year"
              list={yearList}
              value={year}
              onChange={this.onChangeFunc("year")}
              defaultOption="Year"
            />
          </div>
        </div>
        {stories.length > 0 ? (
          <div className="container">
            <LoadMoreStoriesBase
              template={this.StoriesTemplate}
              fields={this.fields}
              params={{ template: "publication" }}
              data={{ stories: stories }}
              numStoriesToLoad={20}
            />
          </div>
        ) : (
          <div styleName="no-stories-found" className="container">
            {isLoading ? null : <Headline text="No stories found!" headerType={4} headerLevel={3} />}
          </div>
        )}
      </React.Fragment>
    );
  }
}

LoadMoreSearchResults.propTypes = {
  data: object,
  itemsToLoad: number,
  setLoading: func,
  isLoading: bool
};

const mapStateToProps = state => {
  return {
    isLoading: state.pageLoading
  };
};

const mapDispatchToProps = dispatch => ({
  setLoading: isLoading => dispatch(setLoading(isLoading))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadMoreSearchResults);
