import React from "react";
import { number, string } from "prop-types";

const ExclamationIcon = ({ color = "#C6CCD5", width = 24.12, height = 23.04 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    viewBox="0 0 24.12 23.04"
  >
    <defs>
      <path
        id="a"
        d="M12 2.875c1.621 0 3.121.388 4.5 1.165a8.914 8.914 0 0 1 3.284 3.147A8.096 8.096 0 0 1 21 11.5c0 1.553-.405 2.99-1.216 4.313A8.914 8.914 0 0 1 16.5 18.96a9.016 9.016 0 0 1-4.5 1.165 9.016 9.016 0 0 1-4.5-1.165 8.914 8.914 0 0 1-3.284-3.148A8.096 8.096 0 0 1 3 11.5c0-1.553.405-2.99 1.216-4.313A8.914 8.914 0 0 1 7.5 4.04 9.016 9.016 0 0 1 12 2.875zm0 15.58a7.279 7.279 0 0 0 3.629-.938 7.174 7.174 0 0 0 2.65-2.54 6.536 6.536 0 0 0 .98-3.477 6.536 6.536 0 0 0-.98-3.478 7.174 7.174 0 0 0-2.65-2.539A7.279 7.279 0 0 0 12 4.544a7.279 7.279 0 0 0-3.629.94 7.174 7.174 0 0 0-2.65 2.538 6.536 6.536 0 0 0-.98 3.478c0 1.252.328 2.411.98 3.478a7.174 7.174 0 0 0 2.65 2.539 7.279 7.279 0 0 0 3.629.939zm1.524-3.616c0 .394-.151.736-.453 1.026-.303.29-.66.434-1.071.434-.411 0-.768-.145-1.07-.434-.303-.29-.454-.632-.454-1.026s.151-.736.453-1.026c.303-.29.66-.435 1.071-.435.411 0 .768.145 1.07.435.303.29.454.632.454 1.026zM10.584 7.5c-.024-.14.007-.25.091-.33a.478.478 0 0 1 .345-.123h1.96c.145 0 .26.041.345.122.084.081.115.191.09.33l-.217 4.73a.559.559 0 0 1-.164.261.389.389 0 0 1-.272.122h-1.524a.389.389 0 0 1-.272-.122.559.559 0 0 1-.164-.26l-.217-4.73z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <use fill={color} xlinkHref="#a" />
      <g fill="#FF214B" mask="url(#b)">
        <path d="M0 0h24v23H0z" />
      </g>
      <path d="M0 0h24v23H0z" mask="url(#b)" />
    </g>
  </svg>
);

ExclamationIcon.propTypes = {
  color: string,
  width: number,
  height: number
};

export default ExclamationIcon;
