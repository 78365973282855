import React from "react";
import { object } from "prop-types";

import Headline from "../../atoms/headline";
import LinkButton from "../../atoms/link-button";
import CardImageType3 from "../../molecules/card-image-type-3";

import "./blogView.m.css";

const BlogView = ({ collection }) => (
  <div className="container">
    <div styleName="header-wrapper">
      <Headline headerLevel={2} headerType={2} text="Blog" />
    </div>

    <div styleName="list">
      {collection.items.slice(0, 2).map((story, index) => (
        <CardImageType3 key={index} story={story} />
      ))}
    </div>

    <div styleName="link-btn">
      <LinkButton slug="/blog" text="READ MORE" />
    </div>
  </div>
);

BlogView.propTypes = {
  collection: object
};

export default BlogView;
