import React from "react";
import { string, func } from "prop-types";

import "./show-more.m.css";

const ShowMore = ({ text, onClick }) => (
  <div styleName="wrapper" className="show-more" onClick={onClick}>
    <span styleName="content">{text}</span>
  </div>
);

ShowMore.defaultProps = {
  text: "SHOW MORE"
};

ShowMore.propTypes = {
  text: string,
  onClick: func
};

export default ShowMore;
