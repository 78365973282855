import React from "react";
import { string } from "prop-types";
import { Link } from "@quintype/components";

import "./link-button.m.css";

const LinkButton = ({ slug, text }) => (
  <Link styleName="wrapper" className="link-button" href={slug}>
    <span styleName="content">{text}</span>
  </Link>
);

LinkButton.defaultProps = {
  text: "KNOW MORE"
};

LinkButton.propTypes = {
  slug: string,
  text: string
};

export default LinkButton;
