import React from "react";
import { object, bool, array, func } from "prop-types";
import { LoadMoreStoriesBase } from "@quintype/components";

import Headline from "../../atoms/headline";
import CardImageType7 from "../../molecules/card-image-type-7";
import ShowMore from "../../atoms/show-more";
import LoadMoreSearchResults from "./load-more-stories";

import "../../pages/listing.m.css";

const TagPage = ({ data }) => {
  const storyFields =
    "headline,subheadline,summary,sections,tags,author-name,author-id,authors,updated-at,last-published-at,published-at,updated-at,first-published-at,metadata,hero-image-metadata,hero-image-s3-key,story-content-id,slug,id,seo,story-template,access";
  const StoriesTemplate = ({ stories, onLoadMore, noMoreStories }) => {
    return (
      <div>
        {stories && stories.map((item, index) => <CardImageType7 story={item} key={index} />)}
        {!noMoreStories &&
          stories.length >= 10 && (
            <div styleName="show-more">
              {" "}
              <ShowMore text="LOAD MORE" onClick={onLoadMore} />
            </div>
          )}
      </div>
    );
  };

  return data.stories.length > 0 ? (
    <div styleName="wrapper">
      <div styleName="title">
        <Headline text={data.tag.name} headerType={1} headerLevel={2} />
      </div>
      <div styleName="result">
        <div styleName="content-left">
          {data.stories && data.isEntity && <LoadMoreSearchResults data={data} itemsToLoad={10} />}
          {data.stories &&
            !data.isEntity && (
              <LoadMoreStoriesBase
                template={StoriesTemplate}
                params={{ "tag-slugs": data.tag.slug }}
                data={data}
                fields={storyFields}
                numStoriesToLoad={10}
              />
            )}
        </div>
      </div>
    </div>
  ) : (
    <div styleName="wrapper no-stories-found title">
      <Headline text="No stories found!" headerType={1} headerLevel={2} />
    </div>
  );
};

TagPage.propTypes = {
  data: object,
  onLoadMore: func,
  stories: array,
  noMoreStories: bool
};

export { TagPage };
