import React, { useState } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import PropTypes from "prop-types";

import { isValidEmail } from "../../helper/utils";
import { subscribeToMailchimp } from "./api.js";
import "./newsletter.m.css";

const NewsletterSubscriptionBase = ({ mailChimpUrl }) => {
  const [email, setEmail] = useState();
  const [error, setError] = useState();
  const [subscribeMailStatus, setSubscribeMailStatus] = useState();
  const onChange = e => {
    setEmail(e.target.value);

    e.target.value ? setError("") : setError("Please provide Email !!");
  };

  const onSubmitClick = () => {
    if (email) {
      if (!isValidEmail(email)) {
        setError("Invalid Email !!");
        // In case if previous success/error messages are set -> remove it
        setSubscribeMailStatus(null);
      } else {
        subscribeToMailchimp({ email: email })
          .then(status => {
            if (status === "subscribed") {
              setSubscribeMailStatus("success");
              setEmail(null);
              setError(null);
            } else {
              setSubscribeMailStatus("failure");
            }
          })
          .catch(() => {
            setSubscribeMailStatus("failure");
          });
      }
    } else {
      setError("Please provide Email !!");
      // In case if previous success/error messages are set -> remove it
      setSubscribeMailStatus(null);
    }
  };
  return (
    <div styleName="wrapper">
      <div styleName="heading">NCF Newsletter</div>
      <div styleName="grid-wrapper">
        <p styleName="description">To receive updates about our work, subscribe to BushChat—our newsletter</p>
        <div styleName="subscription-banner">
          {error && <span styleName="error">{error}</span>}

          <div styleName="email-container">
            <input
              type="email"
              name="EMAIL"
              styleName="email"
              id="subscriber-email"
              placeholder="Email Address"
              required
              onChange={onChange}
              value={email}
            />
            <label styleName="submit">
              <button
                type="submit"
                onClick={onSubmitClick}
                value="Subscribe"
                name="subscribe"
                styleName="button"
                className="button"
              >
                subscribe{" "}
              </button>
            </label>
          </div>
          {subscribeMailStatus === "success" && <div styleName="message">Thank you for subscribing!</div>}
          {subscribeMailStatus === "failure" && <div styleName="message">Something went wrong. Please try again.</div>}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    mailChimpUrl: get(state, ["qt", "config", "mailChimpUrl"], "")
  };
};

NewsletterSubscriptionBase.propTypes = {
  mailChimpUrl: PropTypes.string
};

export const NewsletterSubscription = connect(
  mapStateToProps,
  null
)(NewsletterSubscriptionBase);
