import React from "react";
import { object } from "prop-types";

import Carousel from "../../atoms/carousel";
import CardImageType9 from "../../molecules/card-image-type-9";

import "./story-slider.m.css";

const StorySlider = ({ collection }) => {
  const stories = collection.items.filter(item => item.type === "story");

  const children = stories.map((item, index) => <CardImageType9 key={index} story={item.story} />);

  return (
    <div className="container" styleName="wrapper">
      <Carousel
        carouselName={`carousel-${collection.slug}`}
        options={{
          type: "slider",
          perView: 1,
          gap: 15,
          breakpoints: { 480: { perView: 1, peek: { before: 0, after: 80 } } }
        }}
        totalItems={stories.length}
        showArrows={true}
        renderCenterLeftControls={({ previousSlide, currentSlide }) => {
          return (
            currentSlide !== 0 && (
              <button styleName="glide-arrow-button" onClick={previousSlide}>
                <i styleName="arrow left" />
              </button>
            )
          );
        }}
        renderCenterRightControls={({ nextSlide }) => (
          <button styleName="glide-arrow-button" onClick={nextSlide}>
            <i styleName="arrow right" />
          </button>
        )}
      >
        {children}
      </Carousel>
    </div>
  );
};

StorySlider.propTypes = {
  collection: object
};

export default StorySlider;
