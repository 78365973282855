import React, { useState, useEffect } from "react";
import { object, func } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";

import { setLoading } from "../../helper/actions";
import { getAuthorStories } from "../../helper/api";
import { sortStories } from "../../helper/utils";
import CardImage from "../../atoms/card-image";
// import TopStories from "../../rows/top-stories";

import Headline from "../../atoms/headline";
import Author from "../../atoms/icons/author";
import Twitter from "../../atoms/icons/twitter";
import FeaturedProjectsRow from "../../rows/featured-projects";
import PublicationsRow from "../../rows/publications";

import "./author.m.css";

const AuthorPageView = ({ data, setLoading }) => {
  const { author, params } = data;
  const authorStories = data.stories;
  const authorDesignation = get(author, ["metadata", "Designation"], "");
  const authorProgramme = get(author, ["metadata", "Home Programme"], "");
  const qualification = get(author, ["metadata", "Qualifications"], "");
  const authorEmail = get(author, ["metadata", "Email"], null);
  const authorStatus = get(author, ["metadata", "status"], null);
  const authorSlug = get(params, ["authorSlug"], null);
  const authorAvatarUrl = get(data, ["author", "avatar-url"]);
  const avatarURL = get(data, ["author", "avatar-s3-key"]);
  const projectStories = authorStories.filter(item => item["story-template"] === "project") || [];
  // const blogStories = authorStories.filter(item => item["story-template"] === "text") || [];

  // const blogCollectionItems = stories => {
  //   const blogCollectionItems = {
  //     items: blogStories.map(item => {
  //       return (item = {
  //         story: item,
  //         type: "story"
  //       });
  //     })
  //   };

  //   return blogCollectionItems;
  // };

  const collectionItem = stories => {
    const collectionItems = {
      items: stories.map(item => {
        return (item = {
          story: item,
          type: "story"
        });
      })
    };
    return collectionItems;
  };

  let imageView = null;

  const storyObj = {
    "hero-image-s3-key": avatarURL,
    "hero-image-metadata": "",
    "hero-image-caption": "Author"
  };

  const [authorStoryList, setAuthorStoryList] = useState(sortStories(authorStories));
  const [projectStoryList, setProjectStoryList] = useState(projectStories);

  useEffect(() => {
    if (params.totalStories >= 40) {
      setLoading(true);

      // Fetch the remaining stories
      getAuthorStories(author.id, params.totalStories, 40)
        .then(remainingStories => {
          if (remainingStories && remainingStories.length) {
            // Merge existing project stories with the received stories after filtering
            setProjectStoryList([
              ...projectStoryList,
              ...remainingStories.filter(item => item.story["story-template"] === "project").map(({ story }) => story)
            ]);

            // Merge existing stories with the received stories
            // Filtering takes place in PublicationsRow component
            const allStories = [...authorStoryList, ...remainingStories.map(({ story }) => story)];

            setAuthorStoryList(sortStories(allStories));
          }
        })
        .finally(() => setLoading(false));
    }
  }, []);

  const notFoundView = (
    <div styleName="author-not-found" className="container">
      <Headline text="Author not found!" headerType={1} headerLevel={2} />
    </div>
  );

  const getEmailView = () => {
    if (authorStatus && authorStatus === "Alumnis") {
      return null;
    }

    if (!authorEmail) {
      return null;
    }

    return <div styleName="author-email">{authorEmail}</div>;
  };

  if (avatarURL) {
    imageView = (
      <CardImage story={storyObj} defaultWidth={240} aspectRatio={[1, 1]} imageType="image1x1" widths={[240, 120]} />
    );
  } else if (authorAvatarUrl) {
    imageView = <img src={authorAvatarUrl} alt={author.name} />;
  } else {
    imageView = <Author width={120} height={120} />;
  }

  return Object.keys(author).length === 0 || !author.id || author.slug !== authorSlug ? (
    notFoundView
  ) : (
    <React.Fragment>
      <div styleName="wrapper" className="container">
        <div styleName="title">
          {authorStatus ? <div styleName="author-status">{authorStatus}</div> : null}
          <Headline text={author.name} headerType={1} headerLevel={2} />
          {getEmailView()}
        </div>

        <p styleName="designation">
          {authorDesignation || ""}
          {authorProgramme
            ? `${authorDesignation ? ", " : ""} ${
                authorProgramme === "Institutional staff" ? "Institutional Staff" : authorProgramme
              }`
            : ""}
        </p>

        {qualification && <p styleName="designation">{qualification}</p>}

        <div styleName="author-bio">
          <div styleName="profile">
            {imageView}
            {author["twitter-handle"] && (
              <a styleName="icon" href={`https://www.twitter.com/${author["twitter-handle"]}`}>
                <Twitter width="18  " height="18" color="var(--brandPrimary)" />
              </a>
            )}
          </div>

          {author.bio && <div styleName="bio" dangerouslySetInnerHTML={{ __html: author.bio }} />}
        </div>
      </div>
      <div styleName="bottom-section">
        <div className="container">
          {/* {blogStories.length > 0 && (
            <div styleName="project-title">
              <Headline text="Blog" headerType={9} headerLevel={3} />
            </div>
          )}
          {blogStories.length > 0 && (
            <TopStories collection={blogCollectionItems()} showTitle={false} showReadmore={false} />
          )} */}
          {projectStoryList.length > 0 && (
            <div styleName="project-title">
              <Headline text="Projects" headerType={9} headerLevel={3} />
            </div>
          )}
          {projectStoryList.length > 0 && (
            <FeaturedProjectsRow collection={collectionItem(projectStoryList)} showTitle={false} />
          )}
          {authorStoryList && <PublicationsRow collection={collectionItem(authorStoryList)} />}
        </div>
      </div>
    </React.Fragment>
  );
};

AuthorPageView.propTypes = {
  data: object,
  setLoading: func
};

const mapDispatchToProps = dispatch => ({
  setLoading: isLoading => dispatch(setLoading(isLoading))
});

const AuthorPage = connect(
  null,
  mapDispatchToProps
)(AuthorPageView);

export { AuthorPage };
