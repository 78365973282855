/* eslint-disable jsx-a11y/img-redundant-alt, import/extensions */
import React from "react";
import { object, shape, array } from "prop-types";

import { getCollectionTemplate } from "../get-collection-template";
import { LazyCollection, WithPreview, replaceAllStoriesInCollection, LazyLoadImages } from "@quintype/components";

export const HomePage = props => (
  <div>
    <LazyLoadImages>
      <LazyCollection
        collection={props.data.collection}
        authors={props.data.authors}
        collectionTemplates={getCollectionTemplate}
        lazyAfter={2}
        entities={props.data.entities.entities}
      />
    </LazyLoadImages>
  </div>
);

HomePage.propTypes = {
  data: shape({
    collection: object,
    authors: array
  })
};

export const HomePagePreview = WithPreview(HomePage, (data, story) =>
  Object.assign({}, data, {
    collection: replaceAllStoriesInCollection(data.collection, story)
  })
);
