import React from "react";
import { object, bool, func } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";

import { logout } from "../../helper/api";
import { setUserLoggedIn, setLoading } from "../../helper/actions";

import Headline from "../../atoms/headline";
import LoadMoreSearchResults from "./load-more-stories";

import "./publications.m.css";

const PublicationView = ({ data, isUserLoggedIn, userObj, setUserLoggedIn, setLoading }) => {
  const logoutClick = () => {
    setLoading(true);

    logout()
      .then(() => setUserLoggedIn(false, null))
      .finally(() => setLoading(false));
  };

  const userView = () => {
    if (isUserLoggedIn) {
      return (
        <span styleName="auth-label">
          Hi {userObj.name}, welcome back
          <a onClick={logoutClick} styleName="logout-link">
            Click here to logout
          </a>{" "}
        </span>
      );
    }

    return (
      <span styleName="auth-label">
        <a href="/signup" styleName="auth-links">
          Register
        </a>{" "}
        or{" "}
        <a href="/signin" styleName="auth-links">
          Login
        </a>{" "}
        to download publications
      </span>
    );
  };

  return (
    <div>
      <div styleName="top-content">
        <Headline headerLevel={2} headerType={3} text="Publications" />

        {userView()}
      </div>

      {<LoadMoreSearchResults data={data} />}
    </div>
  );
};

PublicationView.propTypes = {
  data: object,
  isUserLoggedIn: bool,
  userObj: object,
  setUserLoggedIn: func,
  setEntities: func,
  setLoading: func
};

const mapStateToProps = state => {
  return {
    isUserLoggedIn: get(state, ["authReducer", "isUserLoggedIn"], false),
    userObj: get(state, ["authReducer", "userObj"], null)
  };
};

const mapDispatchToProps = dispatch => ({
  setUserLoggedIn: (isUserLoggedIn, user) => dispatch(setUserLoggedIn(isUserLoggedIn, user)),
  setLoading: isLoading => dispatch(setLoading(isLoading))
});

const PublicationsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicationView);

export { PublicationsPage };
