import React from "react";
import { object } from "prop-types";

import HomeCarousel from "../../molecules/home-carousel";

import "./home-carousel.m.css";

const HomeCarouselRow = ({ collection }) => {
  return (
    <div styleName="wrapper">
      <HomeCarousel
        collection={collection}
        options={{
          type: "carousel",
          perView: 1,
          gap: 24,
          autoplay: 3000
        }}
      />
    </div>
  );
};

HomeCarouselRow.propTypes = {
  collection: object.isRequired
};

export default HomeCarouselRow;
