import React from "react";

import ForgotPassword from "../../rows/forgot-password";

import "./forgot-password.m.css";

const ForgotPasswordPage = () => {
  return (
    <div>
      <div styleName="top-content">
        <span styleName="label">FORGOT PASSWORD</span>
      </div>

      <div styleName="bottom-content">
        <ForgotPassword />
      </div>
    </div>
  );
};

export { ForgotPasswordPage };
