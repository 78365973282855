import React from "react";
import { object } from "prop-types";
import { Link, WithLazy } from "@quintype/components";

import Headline from "../../atoms/headline";
import CardImage from "../../atoms/card-image";
// import { LabelWithBackground } from "../../atoms/label";

import "./card-image-type-5.m.css";

const CardImageType5 = ({ story }) => {
  const storyObj = story.story ? story.story : story;

  // const tags =
  //   storyObj.tags &&
  //   storyObj.tags.map((tag, index) => (
  //     <LabelWithBackground key={`${storyObj.id}-${tag.id}-${index}`} text={tag.name} />
  //   ));

  return (
    <div styleName="container">
      <Link href={`/${storyObj.slug}`}>
        <div styleName="wrapper" className="card-image-type-5">
          {storyObj["hero-image-s3-key"] && (
            <WithLazy margin="50px">
              {() => (
                <CardImage
                  story={storyObj}
                  defaultWidth={36}
                  aspectRatio={[16, 9]}
                  imageType="image16x9"
                  widths={[360, 400]}
                />
              )}
            </WithLazy>
          )}
        </div>
        {/* <div styleName="tags">{tags}</div> */}
        <Headline text={storyObj.headline} headerType={2} headerLevel={3} />
        <p styleName="summary">{storyObj.summary}</p>
      </Link>
    </div>
  );
};

export default CardImageType5;

CardImageType5.propTypes = {
  story: object
};
