/* eslint no-undef: "error" */

import React, { useState, useEffect } from "react";
import { object, array, func } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";

import Headline from "../../atoms/headline";
import Carousel from "../../atoms/carousel";
import Arrow1 from "../../atoms/icons/arrow-1";
import Arrow2 from "../../atoms/icons/arrow-2";
import CardImageType2 from "../../molecules/card-image-type-2";
import CardImageType5 from "../../molecules/card-image-type-5";
import PublicationsRow from "../../rows/publications";
import PeopleTeamRow from "../../rows/people-team";
import Entities from "../../molecules/entities";

import { setPublicationStories, setLoading } from "../../helper/actions";
import { getSectionStories } from "../../helper/api";

import "./section.m.css";

const CollectionDom = ({ collection }) => {
  // Display latest 15 stories
  const collectionStories =
    collection.items &&
    collection.items.filter(
      item =>
        item.type === "story" &&
        item.story &&
        item.story["story-template"] === "project" &&
        item.story["hero-image-s3-key"] != null
    );

  // If empty return null
  if (!collectionStories) {
    return null;
  }

  return (
    <div styleName="collection-item-wrapper">
      <Headline text={collection.name} headerType={9} headerLevel={2} />
      <p styleName="description">{collection.summary}</p>
      <div styleName="collection-stories">
        {collectionStories.slice(0, 15).map(
          (story, key) =>
            story && (
              <div key={key}>
                <CardImageType5 story={story} />
              </div>
            )
        )}
      </div>
    </div>
  );
};

CollectionDom.propTypes = {
  collection: object
};

const Subcollections = ({ collections }) =>
  collections.map(collection => <CollectionDom key={collection.id} collection={collection} />);

Subcollections.propTypes = {
  collections: array
};

// Get all the authors from collections and filler story (1st story in data)
const getAllAuthors = ({ collection }) => {
  const subCollections = collection.items.filter(item => item.type === "collection");
  const fillerStoryAuthors = get(collection, ["items", "0", "story", "authors"], []);
  const authorList = [];

  // Don't take authors from conservation-leadership (29145)
  if (collection.id !== 29145) {
    subCollections.forEach(collectionObj => {
      // Fetch the project stories which has authors
      const stories = collectionObj.items.filter(
        item =>
          item.type === "story" &&
          item.story["story-template"] === "project" &&
          item.story.authors &&
          item.story.authors.length
      );

      stories.forEach(({ story }) => {
        // Add the authors to list checking if author already exists
        story.authors.forEach(author => !authorList.some(a => a.id === author.id) && authorList.push(author));
      });
    });
  }

  if (fillerStoryAuthors && fillerStoryAuthors.length) {
    fillerStoryAuthors.forEach(author => !authorList.some(a => a.id === author.id) && authorList.push(author));
  }

  // Don't show admin
  return authorList.filter(c => c.slug !== "admin");
};

const SectionPageView = ({ data, publicationStoriesObj, setPublicationStories, setLoading, entityList }) => {
  const [publicationStoryList, setPublicationStoryList] = useState([]);
  const collection = data.collection;
  const sectionId = get(collection, ["rules", "section-id"], "");
  const totalCount = collection["total-count"] || 0;
  const subCollections = collection.items.filter(item => item.type === "collection");
  const authors = getAllAuthors(data);
  const firstStory = collection.items.find(c => c.type === "story") || {};
  const subHeadline = get(firstStory, ["story", "subheadline"], null);
  const supporterTitle = get(collection, ["metadata", "support"], "Programme Funding");
  const peopleGrouping = get(collection, ["metadata", "people-grouping", "0", "key"], "no") === "yes";

  const getImagesFromCards = () => {
    return (
      firstStory &&
      firstStory.story &&
      firstStory.story.cards &&
      firstStory.story.cards.reduce((accumulator, currentValue) => {
        const storyElements = currentValue["story-elements"];
        const imageElements = storyElements.filter(element => element.type === "image");
        accumulator = accumulator.concat(imageElements);
        return accumulator;
      }, [])
    );
  };

  useEffect(() => {
    // Check if section id exists in publicationStoriesObj (store object)
    if (sectionId in publicationStoriesObj) {
      setPublicationStoryList(publicationStoriesObj[sectionId]);
    } else {
      setLoading(true);
      const MAX_LIMIT = 150; // Max stories to fetch in a API call
      let totalRecords = totalCount; // Backup the total count in a variable
      let temp = 0;
      let offset = 0;
      const list = [];
      const storyApiCallbackList = [];

      // Below while loop prepares the limit and offset
      // Example if totalCount is 550 and MAX_LIMIT is 200 then
      // list will have [{ limit: 200, offset: 0}, { limit: 200, offset: 200}, { limit: 150, offset: 400}]
      while (totalRecords > 0) {
        temp = totalRecords - MAX_LIMIT;

        if (temp > 0) {
          list.push({ limit: MAX_LIMIT, offset });
          offset += MAX_LIMIT;
        } else {
          list.push({ limit: totalRecords, offset });
          offset += totalRecords;
        }

        totalRecords = totalRecords - MAX_LIMIT;
      }

      for (let index = 0; index < list.length; index++) {
        const element = list[index];
        storyApiCallbackList.push(getSectionStories(sectionId, element.limit, element.offset));
      }

      Promise.all(storyApiCallbackList)
        .then(results => {
          const stories = [].concat.apply([], results);

          setPublicationStories(sectionId, stories);
        })
        .finally(() => setLoading(false));
    }
  });

  const images = getImagesFromCards();
  const children =
    images &&
    images.map((image, index) => (
      <div key={`image-${index}`} styleName="carousel-wrapper">
        <CardImageType2 image={image} />
        <div styleName="image-details">
          <p styleName="image-attribution">{image["image-attribution"]}</p>
        </div>
      </div>
    ));

  const partnerEntities = get(collection, ["metadata", "entities", "collectionEntities", "Partner"], []);

  const getFunderEntities = () => {
    const funderEntities1 = get(collection, ["metadata", "entities", "collectionEntities", "Funder"], []);
    const newList = [];

    funderEntities1.filter(c => c).forEach(element => {
      const obj = entityList.find(c => c.id === element.id);

      if (obj) {
        newList.push(obj);
      }
    });

    if (!newList.length) {
      return null;
    }

    return (
      <div className="container" styleName="entities">
        <Headline text={supporterTitle} headerLevel={2} headerType={9} />
        <Entities entities={newList} />
      </div>
    );
  };

  return (
    <div>
      <div styleName="wrapper">
        <div styleName="section-header">
          {collection.name && <Headline text={collection.name} headerType={3} headerLevel={2} />}
        </div>

        {images &&
          images.length > 1 && (
            <Carousel
              carouselName={`carousel-${collection.slug}`}
              options={{
                type: "carousel",
                perView: 1,
                autoplay: 3000,
                gap: 24
              }}
              totalItems={images.length}
              showArrows={false}
              showDots={true}
              renderCenterLeftControls={({ previousSlide, currentSlide }) => {
                return (
                  currentSlide !== 0 && (
                    <button onClick={previousSlide} className="slider-btn">
                      <Arrow2 />
                    </button>
                  )
                );
              }}
              renderCenterRightControls={({ nextSlide }) => (
                <button onClick={nextSlide} className="slider-btn">
                  <Arrow1 />
                </button>
              )}
            >
              {children}
            </Carousel>
          )}
        {images && images.length === 1 && children}

        {subHeadline && (
          <p className="container" styleName="subheadline">
            {subHeadline}
          </p>
        )}

        <div className="container" styleName="sub-collection">
          <Subcollections collections={subCollections} />
        </div>

        <div styleName="authors-wrapper" className="container">
          <PeopleTeamRow authors={authors} showAlumnus={peopleGrouping} isGropuBy={peopleGrouping} />
        </div>

        {getFunderEntities()}

        {partnerEntities.length > 0 && (
          <div className="container" styleName="entities">
            <Headline text="Partners" headerLevel={2} headerType={9} />
            <Entities entities={partnerEntities} />
          </div>
        )}

        <div className="container">
          <PublicationsRow isCollection={false} storyList={publicationStoryList} />
        </div>
      </div>
    </div>
  );
};

SectionPageView.propTypes = {
  data: object,
  publicationStoriesObj: object,
  setPublicationStories: func,
  setLoading: func,
  entityList: array
};

const mapStateToProps = state => {
  return {
    publicationStoriesObj: get(state, ["publicationStoriesReducer"], {}),
    entityList: get(state, ["entityReducer"], [])
  };
};

const mapDispatchToProps = dispatch => ({
  setPublicationStories: (key, value) => dispatch(setPublicationStories(key, value)),
  setLoading: isLoading => dispatch(setLoading(isLoading))
});

const SectionPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(SectionPageView);

export { SectionPage };
