import React from "react";

import SignUp from "../../rows/signup";

import "./signup.m.css";

const SignUpPage = () => {
  return (
    <div>
      <div styleName="top-content">
        <span styleName="signup-label">SIGN UP</span>
        <span styleName="already-user">
          Already a User?{" "}
          <a href="/signin" styleName="click-here">
            Click here
          </a>{" "}
          to Login.
        </span>
      </div>
      <div styleName="bottom-content">
        <SignUp />
      </div>
    </div>
  );
};

export { SignUpPage };
