import React from "react";
import { object } from "prop-types";

import Headline from "../../atoms/headline";
import LoadMoreSearchResults from "./load-more-stories";

import "../../pages/listing.m.css";

const SearchPage = ({ data }) => {
  return data.total > 0 ? (
    <div styleName="wrapper">
      <div styleName="title">
        <Headline text={`SEARCH RESULTS FOR ${data.query}`} headerType={1} headerLevel={2} />
      </div>
      <div styleName="result">
        <div styleName="content-left">{data.stories && <LoadMoreSearchResults data={data} itemsToLoad={10} />}</div>
      </div>
    </div>
  ) : (
    <div styleName="wrapper no-stories-found title">
      <Headline text="No stories found!" headerType={1} headerLevel={2} />
    </div>
  );
};

SearchPage.propTypes = {
  data: object
};

export { SearchPage };
