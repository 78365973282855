import React from "react";
import { object, array, bool } from "prop-types";

import CardImageType6 from "../../molecules/card-image-type-6";
import Headline from "../../atoms/headline";

import "./publications.m.css";

// Component can take either a collection or array of stories
const PublicationsRow = ({ collection, storyList, isCollection }) => {
  let publicationStories = null;

  if (isCollection) {
    const stories = collection.items.filter(item => item.type === "story");
    publicationStories = stories.filter(({ story }) => story["story-template"] === "publication");
  } else {
    publicationStories = storyList;
  }

  if (!(publicationStories && publicationStories.length)) {
    return null;
  }

  const children = publicationStories.map(({ story }, index) => <CardImageType6 key={index} story={story} />);

  return (
    <div styleName="publication-container">
      <div styleName="header-wrapper">
        <Headline text="Publications" headerType={7} headerLevel={2} />
      </div>
      <div styleName="wrapper">{children}</div>
    </div>
  );
};

PublicationsRow.defaultProps = {
  isCollection: true
};

PublicationsRow.propTypes = {
  collection: object,
  storyList: array,
  isCollection: bool
};

export default PublicationsRow;
