import React from "react";
import { array } from "prop-types";

import "./entities.m.css";

const Entities = ({ entities }) => {
  if (!(entities && entities.length)) {
    return null;
  }

  return (
    <ul styleName="wrapper">
      {entities.map(
        (item, index) =>
          item.url ? (
            <li key={index}>
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                {item.name}
              </a>
            </li>
          ) : (
            <li key={index}>{item.name}</li>
          )
      )}
    </ul>
  );
};

Entities.propTypes = {
  entities: array
};

export default Entities;
