import React from "react";
import { object } from "prop-types";
import get from "lodash/get";
import { Link } from "@quintype/components";

import Headline from "../../atoms/headline";
import CardImage from "../../atoms/card-image";

import "./card-image-type-9.m.css";

const CardImageType9 = ({ story }) => {
  const storyObj = story.story ? story.story : story;
  const subject = get(story, ["metadata", "story-attributes", "subject", "0"], null);

  return (
    <div styleName="wrapper" className="card-image-type-9">
      <Link href={`/${storyObj.slug}`}>
        <CardImage
          story={storyObj}
          defaultWidth={36}
          aspectRatio={[1, 1]}
          imageType="image1x1"
          showAlternateImage={true}
        />
      </Link>

      <div styleName="card-content">
        {subject ? (
          <Link href={`/${storyObj.slug}`}>
            <div styleName="subject">{subject}</div>
          </Link>
        ) : null}

        <Link href={`/${storyObj.slug}`}>
          <Headline text={storyObj.headline} headerType={10} headerLevel={3} />
        </Link>

        <Link href={`/${storyObj.slug}`}>
          <Headline text={storyObj.subheadline} headerType={11} headerLevel={3} />
        </Link>

        <Link styleName="know-more" href={`/${storyObj.slug}`}>
          KNOW MORE
        </Link>
      </div>
    </div>
  );
};

export default CardImageType9;

CardImageType9.propTypes = {
  story: object
};
