import React from "react";
import { object, bool } from "prop-types";

import Headline from "../../atoms/headline";
import CardImageType4 from "../../molecules/card-image-type-4";
import CardImageType3 from "../../molecules/card-image-type-3";
import LinkButton from "../../atoms/link-button";

import "./top-stories.m.css";

const TopStoriesRow = ({ collection, showTitle = true, showReadmore = false }) => {
  // Only text stories (Note: text is used as blog stories)
  if (!(collection.items && collection.items.length)) {
    return null;
  }

  const storiesFromCollection = collection.items.filter(
    item =>
      item.type === "story" &&
      item.story &&
      (item.story["story-template"] === "text" ||
        item.story["story-template"] === "news-elsewhere" ||
        item.story["story-template"] === "visual-story")
  );
  const [firstStory, ...otherStories] = storiesFromCollection;
  const otherStoriesChildren = otherStories
    .slice(0, 4)
    .map((story, index) => <CardImageType3 key={index} story={story} isNcfNews={false} />);

  return (
    <div styleName="wrapper" className="container">
      {showTitle && (
        <div styleName="header-wrapper">
          <Headline headerLevel={2} headerType={2} text={collection.name || "TOP STORIES"} />
        </div>
      )}
      <div styleName="grid-wrapper">
        <div styleName="first-story">
          <CardImageType4 story={firstStory} isNcfNews={false} />
        </div>
        <div styleName="other-stories">{otherStoriesChildren}</div>
      </div>
      {showReadmore && (
        <div styleName="readmore">
          <LinkButton slug="/blog" text="READ MORE" showReadmore={showReadmore} />
        </div>
      )}
    </div>
  );
};

TopStoriesRow.propTypes = {
  collection: object,
  showTitle: bool,
  showReadmore: bool
};

export default TopStoriesRow;
