import React from "react";
import { object } from "prop-types";
import { Link } from "@quintype/components";

import Headline from "../../atoms/headline";
import { LabelWithBackground } from "../../atoms/label";
import CardImage from "../../atoms/card-image";

import "./card-image-type-8.m.css";

const CardImageType8 = ({ story }) => {
  const storyObj = story.story ? story.story : story;

  return (
    <div className="card-image-type-8">
      <Link href={`/${storyObj.slug}`}>
        {storyObj["hero-image-s3-key"] && (
          <CardImage
            story={storyObj}
            defaultWidth={36}
            aspectRatio={[16, 9]}
            imageType="image16x9"
            widths={[364, 728]}
          />
        )}
      </Link>
      <div styleName="card-content">
        <Link href={`/${storyObj.slug}`}>
          <div styleName="tags">
            {storyObj.tags &&
              storyObj.tags
                .filter(item => item["tag-type"] === "Entity")
                .map(tag => <LabelWithBackground key={tag.id} text={tag.name} />)}
          </div>
          <Headline text={storyObj.headline} headerType={10} headerLevel={3} />
          <Headline text={storyObj.subheadline} headerType={11} headerLevel={3} />
        </Link>
      </div>
    </div>
  );
};

export default CardImageType8;

CardImageType8.propTypes = {
  story: object
};
