import React from "react";
import { object } from "prop-types";

import AboutUsMolecule from "../../molecules/about-us";
import { NewsletterSubscription } from "../../molecules/newsletter";

const AboutUs = ({ collection }) => {
  const story = collection.items[0];

  return (
    <div className="container">
      <AboutUsMolecule story={story} />
      <NewsletterSubscription />
    </div>
  );
};

AboutUs.propTypes = {
  collection: object
};

export default AboutUs;
